<template>
  <div class="shadow rounded-t-2xl" v-show="open">
    <div class="px-8 rounded-t-2xl py-4 bg-[#EAF5FD] flex flex-nowrap justify-between items-center">
      <span class="font-medium">{{ title }}</span>

      <div class="flex flex-nowrap gap-6">
        <v-icon v-if="expand" icon="expand_more" size="16" @click="expand = false"></v-icon>
        <v-icon v-if="!expand" icon="expand_less" size="16" @click="expand = true"></v-icon>
        <v-icon icon="close" size="16" @click="cancelAllDialog()"></v-icon>
      </div>
    </div>
    <div class="bg-white px-8 py-4 flex flex-col gap-6 text-sm max-h-[300px] overflow-y-auto" v-show="expand">
      <div v-for="(file, index) in sortedFileStats" class="flex flex-nowrap justify-between items-center gap-4"
        :key="`file-${index}`">
        <div class="flex gap-4 items-center">
          <img :src="fileType(file)" width="24" height="24" />
          <span>{{ file.name }}</span>
        </div>

        <div v-if="file.status == 'cancelled'" class="text-error">Cancelled</div>
        <div v-else class="flex items-center gap-4">
          <template v-if="file.status == 'completed'">
            <v-icon icon="check_circle" size="24" color="success"></v-icon>
            <v-icon icon="arrow_forward_ios" size="16" @click="glibWindowsOpen(file.gotoUrl)"></v-icon>
          </template>
          <template v-else>
            <v-progress-circular size="24" :rotate="360" :model-value="file.progress.value"
              color="primary"></v-progress-circular>
            <v-icon icon="close" size="16" @click="cancel(file)"></v-icon>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, nextTick } from "vue";
import { uploadOneFile } from "glib-web/components/composable/upload";
import { useFilesState, useFileUtils } from "glib-web/components/composable/file";
import { watch } from "vue";
import { vueApp } from "glib-web/store";

const { Item } = useFileUtils();

const props = defineProps(['spec']);
const imageSource = props.spec.image;

const open = ref(false);
const expand = ref(true);
const fileStats = ref({});

const sortedFileStats = computed(() => {
  return Object.values(fileStats.value).toReversed();
});

const { uploaded, uploading, uploadedFileLength, uploadingFileLength } = useFilesState(fileStats);

const title = computed(() => {
  if (uploading.value) return `Uploading ${uploadingFileLength.value} item${uploadingFileLength.value > 1 ? "(s)" : ""}`;
  if (uploaded.value) return `${uploadedFileLength.value} upload${uploadedFileLength.value > 1 ? "s" : ""} completed`;
  return '';
});

// Prompt user close/reload button clicked
const prompt = (event) => {
  event.preventDefault();

  const text = 'Changes you made have not been saved. Leave site?';

  return confirm(text);
};

watch(uploading, (val) => {
  if (val) {
    window.onbeforeunload = prompt;
  } else {
    window.onbeforeunload = null;
  }
});

function cancel(file) {
  if (file.status != 'pending') return;

  const xhr = file.xhr;
  if (xhr) {
    xhr.abort();
  }

  file.status = 'cancelled';
};

function cancelAllDialog() {
  if (!uploading.value) {
    close(Object.values(fileStats.value));
    return;
  }

  GLib.action.execute({ action: 'dialogs/open', url: props.spec.dialog_url, width: 684 }, {});
}

function close(files) {
  files.forEach((file) => cancel(file));
  open.value = false;
  fileStats.value = {};
}

function fileType(file) {
  const mime = file.type;
  let type = '';
  if (mime.startsWith('image/')) {
    type = "image";
  } else if (["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(mime)) {
    type = "doc";
  } else if (mime == "application/pdf") {
    type = "pdf";
  } else if (['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(mime)) {
    type = "ppt";
  } else if (['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(mime)) {
    type = "xls";
  } else if (mime == 'text/csv') {
    type = "csv";
  } else {
    type = "pdf";
  }

  return imageSource[type];
}

function isTagged(files) {
  return !!Object.values(files)[0].subsection_id;
}

function getFileIds(url) {
  const obj = new URL(url);
  return { sectionId: obj.searchParams.get('section_id'), subsectionId: obj.searchParams.get('subsection_id'), onboarding: obj.searchParams.get('onboarding') };
}

function glibWindowsOpen(url) {
  if (!url) return;
  GLib.action.execute({
    action: 'windows/open',
    url: url
  }, {});
}

function saveRecord(file) {
  const { section_id, subsection_id } = file.el;

  const saveUrl = new URL(props.spec.save_url);
  saveUrl.searchParams.set('section_id', section_id);
  saveUrl.searchParams.set('subsection_id', subsection_id);

  let gotoUrl = props.spec.goto_url;
  gotoUrl = gotoUrl.replace('section_id', section_id);
  gotoUrl = gotoUrl.replace('subsection_id', subsection_id);

  GLib.action.execute({
    action: 'http/post',
    url: saveUrl.toString(),
    formData: { signed_id: file.signedId }
  }, {});

  file.gotoUrl = gotoUrl;
}

watch(vueApp.confirmationDialog, (val) => {
  const { cancelAll } = val;
  if (cancelAll) {
    close(Object.values(fileStats.value));
    delete vueApp.uploader.files;
    delete vueApp.confirmationDialog.cancelAll;

    GLib.action.execute({
      action: 'snackbars/alert',
      message: 'File upload was cancelled',
      styleClasses: ['vertical', 'outlined', 'error'],
      buttons: [{
        text: 'Dismiss'
      }]
    }, {});
  }
});

// watch file upload
watch(vueApp.uploader, (val) => {
  if (!val.files) return;

  if (!isTagged(val.files)) {
    const url = new URL(props.spec.tag_url);
    let { sectionId, subsectionId, onboarding } = getFileIds(val.spec.url);

    // from treeView
    const { to } = val.spec;

    if (to) {
      sectionId ||= to.section_id;
      subsectionId ||= to.subsection_id;
    }


    if (sectionId) url.searchParams.set('section_id', sectionId);
    if (subsectionId) url.searchParams.set('subsection_id', subsectionId);
    if (onboarding) url.searchParams.set('onboarding', true);

    GLib.action.execute({ action: 'http/post', url: url.toString(), formData: { files: val.files } }, {});
    return;
  }
  open.value = true;
  expand.value = true;

  const newFiles = Object.entries(val.files).reduce((prev, curr) => {
    const key = curr[0];
    const file = curr[1];
    prev[key] = new Item({ status: 'pending', el: file });
    return prev;
  }, {});
  fileStats.value = Object.assign(fileStats.value, newFiles);

  Object.keys(newFiles).forEach(async (key) => {
    uploadOneFile({
      files: fileStats,
      key,
      spec: val.spec,
      onAfterUploaded: saveRecord
    });
  });

  delete vueApp.uploader.files;
});

</script>

<style scoped>
.shadow {
  box-shadow: 0px 4px 12px 0px #0000001A;
}
</style>